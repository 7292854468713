import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>Let&apos;s stay in touch</h1>
    <p>
      Follow and message me on{' '}
      <a
        href="https://twitter.com/dbrucewong"
        rel="noopener noreferrer"
        target="_blank"
      >
        Twitter
      </a>
      ,
    </p>
    <p>
      connect with me on{' '}
      <a
        href="https://www.linkedin.com/in/dawong/"
        rel="noopener noreferrer"
        target="_blank"
      >
        LinkedIn
      </a>
      ,
    </p>
    <p>
      or send me an{' '}
      <a href="mailto:dbw@dbw.dev">old-fashioned electronic mail</a> 🥃.
    </p>
  </Layout>
);

export default ContactPage;
